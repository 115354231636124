<template>
  <div id="layout" v-loading="isLoading">
    <div id="c_grid-116273709439191" loaded="true">    
        <div class="p_gridbox signal s_tmpl">
            <div id="content_box-116273709439191-0" class="d_gridCell_0 p_gridCell ND_empty">
                <div id="c_navigation_115-1669818225509" loaded="true" class="response-animated">
                    <div class="e_container-11 s_layout">
                        <div class="cbox-11-0 p_item">
                            <div class="e_image-3 s_img">
                                <img src="@/assets/image/a726d896-fb60-4f60-bc09-cb999f7fac9d.jpg" alt="北京华鼎博视数据信息技术有限公司" title="北京华鼎博视数据信息技术有限公司" la="la">
                            </div>
                        </div>
                        <div class="cbox-11-1 p_item">
                            <div class="e_navigationC-10" needjs="true">
                                <div class="p_navContent">
                                    <ul class="p_navBox1">
                                        <li class="border-bottom p_navItem1">
                                            <p class="p_navCon js_editor_click">
                                                <a href="#" @click.prevent="navigateToHome">
                                                    <span>首页</span>
                                                </a>                                                   
                                            </p>   
                                        </li>
                                        <!-- <li class="border-bottom p_navItem1">
                                            <p class="p_navCon js_editor_click">
                                                <a href="#" @click.prevent="navigateToChain">
                                                    <span>登记验证</span>
                                                </a>
                                            </p>  
                                        </li> -->
                                        <li class="border-bottom p_navItem1">
                                            <p class="p_navCon js_editor_click">
                                                <a href="#">
                                                    <span @click="openNewPage">电子数据篡改监测系统</span>
                                                </a>
                                            </p>                                             
                                        </li>
                                        <li class="border-bottom p_navItem1">
                                            <p class="p_navCon js_editor_click">
                                                <a href="#" @click.prevent="navigateToPrincipleAdvantages">
                                                    <span>原理与优势</span>
                                                </a>
                                            </p>  
                                        </li>
                                        <li class="border-bottom p_navItem1">
                                            <p class="p_navCon js_editor_click">
                                                <a href="#" @click.prevent="navigateToValidityChecking">
                                                    <span>防篡改链</span>
                                                </a>
                                            </p>  
                                        </li>                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="cbox-11-2 p_item">
                            <div class="e_container-12 s_layout" style="max-width: 100%;width: 220px;">
                                <div class="cbox-12-0 p_item">
                                </div>
                                <div class="cbox-12-1 p_item">
                                    <div class="header_container" v-if="isLogin">
                                        <el-dropdown placement='bottom-start' @command="handleCommand">
                                            <img src="@/assets/image/default.jpg" class="avator">
                                            <span style="font-size:14px; margin-left:10px;">{{phoneNum}}</span>
                                            <el-dropdown-menu>
                                                <el-dropdown-item command="userCenter">个人中心</el-dropdown-item>
                                                <el-dropdown-item command="signout">退出</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </div>
                                    <el-button v-else type="primary" style="padding:10px 40px; font-size:15px;" @click="toLogin">登录</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
    <div id="c_banner_028-16697477322890" loaded="true" class="response-animated" style="min-height: 680px;">
        <router-view />
    </div>
    <div id="c_grid-116273709439190">     
        <div class="p_gridbox signal s_tmpl_footer">
            <div id="content_box-116273709439190-0" class="d_gridCell_0 p_gridCell ND_empty">
                <div id="c_static_001-16420726718590" style="background-color: #fff;">
                    <div class="foot" style="width:80%;margin: 0 auto; background-color: #fff;">
                        <div class="company-info" style="text-align: left;">
                            <a target="_self">
                                <img src="@/assets/image/image.png" alt="北京华鼎博视数据信息技术有限公司" title="北京华鼎博视数据信息技术有限公司" la="la" needthumb="false">
                            </a>
                            <p><strong>公司地址：</strong> 北京市海淀区上地信息路2号1号楼16B</p>
                            <p><strong>邮编：</strong> 100085</p>
                            <p><strong>联系电话：</strong> 010-82893324 , 010-82895883</p>
                            <p><strong>传真：</strong> 分机转808</p>
                            <a style="cursor: pointer;">
                                <p><strong>公司网址： </strong>www.widenview.com.cn</p>
                            </a>
                        </div>
                        <div class="map">
                            <mapVue></mapVue>
                        </div>
                    </div>
                    <div style="width:80%;margin:auto;padding-top:1rem;padding-bottom:1rem;text-align:left;font-size: 14px;color: #a9a9a9;line-height: 1.5;font-family: Microsoft YaHei;max-width: none;">
                        <p>版权所有：北京华鼎博视数据信息技术有限公司
                            &nbsp; &nbsp; &nbsp;<img src="@/assets/image/备案图标.png" width="20" height="20"> &nbsp;<a href="https://beian.mps.gov.cn/#/query/webSearch?code=11010802044266" rel="noreferrer" target="_blank">京公网安备11010802044266</a>
                            &nbsp; &nbsp; &nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: #a9a9a9;">京ICP备19054472号-2</a>
                            &nbsp; &nbsp; &nbsp;增值电信业务经营许可证：<a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: #a9a9a9;">京B2-20242160</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import {mapState,mapActions} from 'vuex'
import mapVue from '@/components/map/map.vue';
import Cookies from 'js-cookie';
export default {
    name:'layout',
    components:{
        mapVue
    },
    data(){
        return {
            phoneNum:''
        }
    },
    computed:{
        ...mapState({
            isLoading : state=>state.permission.isLoading,
            isLogin :state=>state.permission.isLogin
        }),
    },
    watch:{
         isLogin(newValue, oldValue){
            if(newValue){
                this.isLoginSuccessful();
            }
         }
    },
    methods:{
        ...mapActions(['setIsLogin']),
        navigateToHome() {
            this.$router.push('/home'); // 跳转到 home 路由
        },
        navigateToChain() {
            this.$router.push('/chain'); // 跳转到 chain 路由
        },
        navigateToPrincipleAdvantages(){
            this.$router.push('/PrincipleAdvantages')
        },
        navigateToValidityChecking(){
            this.$router.push('/ValidityChecking')
        },
        toLogin(){
            this.$router.push('/login');
        },
        handleCommand(command){
            if(command == 'signout'){
                Cookies.remove('db');
                this.setIsLogin(false);
            }else if (command == 'userCenter'){
                this.$router.push('/Personal')
            }
        },
        isLoginSuccessful(){
            const dbCookie = Cookies.get('db');
            if (dbCookie !== undefined) {
                this.phoneNum = JSON.parse(dbCookie).phone;
                this.setIsLogin(true);
            }
        },
        openNewPage(){
            window.open('https://www.yunyanzheng.net:8888','_blank')
        }
    },
    mounted(){
        this.isLoginSuccessful();
    }
}
</script>

<style>
    .foot {
        display: flex;
        align-items: center;
        padding: 20px;
        background-color: #fff;
    }

    .company-info {
        flex: 1;
        padding: 20px;
        border-right: 1px solid #ccc;
        justify-content: center;
    }

    .company-info h2 {
        color: #333;
    }

    .company-info p {
        color: #666;
        margin-bottom: 10px;
    }

    .map {
        flex: 1;
        padding: 20px;
    }

    .map h2 {
        color: #333;
    }

    .map {
        width: 100%;
    }



    .header_container{
		height: 60px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 30px;
	}
	.avator{
        width: 46px;
        height: 46px;
		border-radius: 50%;
	}
    .el-dropdown-menu{
		min-width: 100px;
	}
	.el-dropdown-menu__item{
        text-align: center;
    }
</style>